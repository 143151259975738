import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal, Select } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styles from './newStrategy.less';
import type { INewStrategyStore } from './newStrategyStore';

@observer
class NewStrategy extends Component<{ store: INewStrategyStore; }> {
  render(): ReactNode {
    const {
      visible,
      productList,
      strategyList,
      unfinishedList,
      finishedList,
      deleteList,
      choosedItem,
      loading,
      onChange,
      newAdd,
      cancelChoose,
      onFinish,
      cacelFinish,
      onDelete,
      cancelDelete,
      onClose,
      onSave,
      flag,
    } = this.props.store;
    return (
      <Modal
        bodyStyle={{
          padding: '18px',
          borderRadius: '6px',
          minHeight: '343px',
          maxHeight: '600px',
        }}
        closable={false}
        footer={null}
        open={visible}
        width={664}
        wrapClassName={styles.strategy}
      >
        <div className={styles.strategyWrap}>
          <div className={styles.title}>
            {flag === 'new' ? '分配策略' : '编辑策略'}
          </div>
          <div className={`${styles.mt10} ${styles.content} ${styles.br4}`}>
            <div className={styles.contentLeft}>
              {
                productList.map((el) => {
                  return (
                    <div
                      className={`${styles.contentLeftWrap} ${styles.mt18}`}
                      key={el.gmsGoodsId}
                    >
                      <img
                        className={`${styles.img} ${styles.br4}`}
                        src={el.smallPicUrl}
                      />
                      <div>
                        <div className={styles.productName}>
                          {el.goodsName}
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <div className={styles.contentRight}>
              <div>

                <Select
                  className={styles.chooseStrategy}
                  maxTagCount={'responsive' as const}
                  mode={'multiple' as const}
                  onChange={(v) => onChange(v)}
                  placeholder="请选择运营策略"
                  value={choosedItem}
                >
                  {
                    strategyList.map((el) => (
                      <Select.Option
                        key={el.key}
                        value={el.key}
                      >
                        {el.value}
                      </Select.Option>
                    ))
                  }
                </Select>
                <Button
                  className={styles.addBtn}
                  onClick={newAdd}
                  type="primary"
                >
                  新增
                </Button>
              </div>
              <div className={`${styles.unfinished} ${styles.mt6}`}>
                {
                  unfinishedList.map((el) => {
                    if (el.gmsGoodsStrategyId) {
                      return (
                        <div
                          className={`${styles.flex} ${styles.flexBetween} ${styles.mt12} ${styles.flexColumCenter}`}
                          key={el.key}
                        >
                          <Checkbox
                            checked={false}
                            onClick={() => onFinish(el)}
                          >
                            <span className={`${styles.font14} ${styles.font400} ${styles.clBlack} ${styles.ml2}`}>
                              {el.name}
                            </span>
                          </Checkbox>
                          <DeleteOutlined
                            className={`${styles.deleteIcon} ${styles.font20}`}
                            onClick={() => onDelete(el)}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={`${styles.flex} ${styles.flexBetween} ${styles.mt12}`}
                          key={el.key}
                        >
                          <span className={`${styles.font14} ${styles.font400} ${styles.clBlack} ${styles.ml26}`}>
                            {el.name}
                          </span>
                          <a onClick={() => cancelChoose(el)}>
                            取消选择
                          </a>
                        </div>
                      );
                    }
                  })
                }
              </div>
              <div
                className={`${styles.mt46} ${styles.finish}`}
                style={{ display: finishedList.length ? 'block' : 'none' }}
              >
                <div className={`${styles.flex} ${styles.flexCenter} ${styles.flexColumCenter}`}>
                  <div className={styles.divider}/>
                  <div className={`${styles.dividerText} ${styles.font12} ${styles.font400}`}>
                    已完成
                  </div>
                  <div className={styles.divider}/>
                </div>
                {
                  finishedList.map((el) => {
                    return (
                      <div
                        className={`${styles.mt12} ${styles.clGrey}`}
                        key={el.key}
                      >
                        <Checkbox
                          checked
                          onClick={() => cacelFinish(el)}
                        >
                          <span className={`${styles.font14} ${styles.font400} ${styles.clGrey} ${styles.ml2}`}>
                            {el.name}
                          </span>
                        </Checkbox>
                      </div>
                    );
                  })
                }
              </div>
              <div
                className={styles.mt40}
                style={{ display: deleteList.length ? 'block' : 'none' }}
              >
                <div className={`${styles.flex} ${styles.flexCenter} ${styles.flexColumCenter}`}>
                  <div className={styles.divider}/>
                  <div className={`${styles.dividerText} ${styles.font12} ${styles.font400}`}>
                    已删除
                  </div>
                  <div className={styles.divider}/>
                </div>
                {
                  deleteList.map((el) => {
                    return (
                      <div
                        className={`${styles.flex} ${styles.flexBetween} ${styles.mt12} ${styles.flexColumCenter}`}
                        key={el.key}
                      >
                        <div>
                          <span className={`${styles.font14} ${styles.font400} ${styles.clGrey} ${styles.ml26}`}>
                            {el.name}
                          </span>
                          <span className={`${styles.deleteFlag} ${styles.ml6}`}>
                            已删除
                          </span>
                        </div>
                        <a onClick={() => cancelDelete(el)}>
                          撤销
                        </a>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className={`${styles.btnsWrap} ${styles.flex}`}>
            <Button
              className={styles.addBtn}
              disabled={!finishedList.length && !unfinishedList.length && !deleteList.length}
              loading={loading}
              onClick={onSave}
              type="primary"
            >
              保存
            </Button>
            <Button
              className={styles.addBtn}
              onClick={onClose}
            >
              取消
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default NewStrategy;
