import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { observable, action, toJS } from 'mobx';
import moment from 'moment';
import { api } from '../../../utils';
import type { IParentStore } from '../productAnalysisStore';

interface IInitOptions {
  parent?: IParentStore;
}

interface IProductList {
  goodsName: string;
  smallPicUrl: string;
  gmsGoodsId: number;
}

interface ISave {
  gmsGoodsStrategyId?: number;
  gmsStrategyId: number;
  status: number;
}

interface IStrategyList {
  key: number | string;
  gmsStrategyId: number; // 策略id
  name: string; // 策略名称
  status: number; // 0 已删除，1已完成，2未完成
  createTime?: string;
  gmsGoodsId?: number; // 商品id
  gmsGoodsStrategyId?: number; // 平台商品策略任务id，无表示新增
  submitTime?: number;
}
export default class NewStrategyStore {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public parent;

  @observable public visible = false;

  @observable public loading = false;

  @observable public flag = 'new'; // new分配策略 edit编辑策略

  @observable public choosedItem: number[] = [];

  @observable public productList: IProductList[] = [];

  @observable public strategyList = [];

  @observable public unfinishedList: IStrategyList[] = [];

  @observable public finishedList: IStrategyList[] = [];

  @observable public deleteList: IStrategyList[] = [];

  @observable public saveCb: (id: number) => void;

  @action public onShow = (productList: IProductList[], flag: string, cb?: (id?: number) => void): void => {
    Object.assign(this, {
      productList,
      flag,
      visible: true,
    });
    this.getDict();
    if (flag === 'edit') {
      this.getCurrentStrategy();
    }
    this.saveCb = cb;
  };

  @action public getDict = async(): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.getDict,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify({ commonDictList: ['strategyList']}),
    });
    this.strategyList = res.data.strategyList;
  };

  // 编辑商品策略  查询当前商品策略
  @action public getCurrentStrategy = async(): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.queryCurrentProductTask,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify({ gmsGoodsId: this.productList[0].gmsGoodsId }),
    });
    for (let i = 0; i < res.data.length; i++) {
      const el = res.data[i];
      switch (el.status) {
        case 0:
          this.deleteList.push({
            ...el,
            key: i,
          });
          break;
        case 1:
          this.finishedList.push({
            ...el,
            key: i,
          });
          break;
        case 2:
          this.unfinishedList.push({
            ...el,
            key: i,
          });
          break;
        default:
          break;
      }
    }
  };

  @action public onChange = (v: number[]): void => {
    console.log('onChange', v);
    this.choosedItem = v;
  };

  // 未完成策略不能有重复信息
  @action private checkRepeat = (gmsStrategyId: number): boolean => {
    const _repeat = this.unfinishedList.find((el) => el.gmsStrategyId === gmsStrategyId);
    if (_repeat) {
      message.warning('未完成策略中已经存在该策略，不可以重复添加');
      return false;
    }
    return true;
  };

  // 新增
  @action public newAdd = (): void => {
    const { strategyList, choosedItem, unfinishedList } = this;
    if (!choosedItem.length) {
      message.warning('请选择要添加的策略');
      return;
    }
    for (let i = 0; i < choosedItem.length; i++) {
      const element = choosedItem[i];
      if (!this.checkRepeat(element)) {
        continue;
      }
      const item = strategyList.find((el) => el.key === element);
      unfinishedList.push({
        key: unfinishedList.length ? `${unfinishedList[unfinishedList.length - 1].key}_add` : '1_add',
        status: 2,
        gmsStrategyId: item.key,
        name: item.value,
        createTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        gmsGoodsId: this.productList[0].gmsGoodsId,
      });
    }
    this.choosedItem = [];
    console.log(toJS(strategyList), toJS(unfinishedList));
  };

  // 取消选择
  @action public cancelChoose = (item: IStrategyList): void => {
    const { unfinishedList } = this;
    const _index = unfinishedList.findIndex((el) => el.gmsStrategyId === item.gmsStrategyId);
    unfinishedList.splice(_index, 1);
  };

  // 未完成->已完成
  @action public onFinish = (item: IStrategyList): void => {
    const { finishedList, unfinishedList } = this;
    finishedList.push({
      ...item,
      status: 1,
    });
    const _index = unfinishedList.findIndex((el) => el.gmsStrategyId === item.gmsStrategyId);
    unfinishedList.splice(_index, 1);
  };

  // 已完成->未完成
  @action public cacelFinish = (item: IStrategyList): void => {
    const { finishedList, unfinishedList } = this;
    if (!this.checkRepeat(item.gmsStrategyId)) {
      return;
    }
    unfinishedList.push({
      ...item,
      status: 2,
    });

    // 未完成策略按照创建时间排序
    this.unfinishedList = _.sortBy(unfinishedList, (o) => {
      return Date.parse(o.createTime);
    });
    const _index = finishedList.findIndex((el) => el.gmsStrategyId === item.gmsStrategyId);
    finishedList.splice(_index, 1);
  };

  // 未完成->已删除
  @action public onDelete = (item: IStrategyList): void => {
    const { deleteList, unfinishedList } = this;
    deleteList.push({
      ...item,
      status: 0,
      submitTime: (new Date()).valueOf(),
    });
    const _index = unfinishedList.findIndex((el) => el.gmsStrategyId === item.gmsStrategyId);
    unfinishedList.splice(_index, 1);
  };

  // 已删除 -> 未完成
  @action public cancelDelete = (item: IStrategyList): void => {
    const { deleteList, unfinishedList } = this;
    if (!this.checkRepeat(item.gmsStrategyId)) {
      return;
    }
    unfinishedList.push({
      ...item,
      status: 2,
    });

    // 未完成策略按照创建时间排序
    this.unfinishedList = _.sortBy(unfinishedList, (o) => {
      return Date.parse(o.createTime);
    });
    const _index = deleteList.findIndex((el) => el.gmsStrategyId === item.gmsStrategyId);
    deleteList.splice(_index, 1);
  };

  @action public onClose = (): void => {
    Object.assign(this, {
      visible: false,
      productList: [],
      unfinishedList: [],
      finishedList: [],
      deleteList: [],
      choosedItem: undefined,
    });
  };

  @action private getSaveDate = (list: IStrategyList[]): ISave[] => {
    return list.map((el) => {
      return {
        gmsGoodsStrategyId: el.gmsGoodsStrategyId,
        gmsStrategyId: el.gmsStrategyId,
        status: el.status,
      };
    });
  };

  @action public onSave = (): void => {
    const { productList, finishedList, unfinishedList, deleteList } = this;
    const gmsGoodsIds = this.productList.map((el) => el.gmsGoodsId);
    const _finish = this.getSaveDate(finishedList);
    const _unfinish = this.getSaveDate(unfinishedList);
    const _delete = this.getSaveDate(deleteList);
    const strategyParam = [
      ..._finish,
      ..._unfinish,
      ..._delete,
    ];
    const postData = {
      gmsGoodsIds,
      strategyParam,
    };
    this.loading = true;
    request<BaseData<any>>({
      url: api.editGoodsTask,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify(postData),
    }).then((res) => {
      this.loading = false;
      message.success('保存成功');
      this.saveCb && this.saveCb(gmsGoodsIds[0]);
      this.onClose();
    })
      .finally(() => {
        this.loading = false;
      });
  };
}

export type INewStrategyStore = NewStrategyStore;
