import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import React from 'react';
import { api } from '../../../utils';
import type { IParentStore } from '../store';

interface IInitOptions {
  parent?: IParentStore;
}

interface IStrategyList {
  gmsStrategyId: number;
  name: string;
  isEnable: boolean;
  isEditable: boolean;
}

export default class StrategyManagementStore {
  constructor(options: IInitOptions) {
    this.parent = options.parent;
  }

  @observable public visible = false;

  @observable public newAdd = '';

  @observable public parent: IParentStore ;

  @observable public list: IStrategyList[] = [];

  @action public onShow = (): void => {
    this.getAllStrategy();
    this.visible = true;
  };

  @action public getAllStrategy = async(): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.queryAllStrategy,
      method: 'get',
    });
    this.list = res.data.map((el) => {
      return {
        ...el,
        isEditable: false,
      };
    });
  };

  @action public onClose = (): void => {
    this.visible = false;
    this.newAdd = '';
    this.parent.onTabChange(this.parent.activeTab);
    this.parent.getTaskCount();
  };

  // 修改策略名称和启用禁用
  @action public editStrategy = async(gmsStrategyId: number, key: string, value): Promise<void> => {
    if (key === 'name' && (value.length > 10 || !value)) {
      message.warning('策略名称为1-10个字');
      return;
    }

    const res = await request<BaseData<any>>({
      url: api.editSingleStrategy,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: {
        gmsStrategyId,
        [key]: value,
      },
    });
    message.success(res.data);
    if (key === 'name') {
      this.onchange(gmsStrategyId, 'isEditable', false);
    }
    const item = this.list.find((el) => el.gmsStrategyId === gmsStrategyId);
    item[key] = value;
  };

  @action public onchange = (gmsStrategyId: number, key: string, value: boolean | string): void => {
    const item = this.list.find((el) => el.gmsStrategyId === gmsStrategyId);
    item[key] = value;
  };

  @action public onDelete = async(gmsStrategyId: number): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.deleteStrategyCheck,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify({ gmsStrategyId }),
    });
    Modal.confirm({
      title: `该策略还有${res.data}个未完成任务`,
      content: '删除后对应的未完成和已完成任务会被删除。',
      okText: '删除',
      cancelText: '取消',
      onOk: () => this.deleteStrategy(gmsStrategyId),
    });
  };

  @action private deleteStrategy = async(gmsStrategyId: number): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.deleteStrategy,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify({ gmsStrategyId }),
    });
    message.success(res.data);
    const _index = this.list.findIndex((el) => el.gmsStrategyId === gmsStrategyId);
    this.list.splice(_index, 1);
  };

  @action public onNewChange = (value: string): void => {
    this.newAdd = value;
  };

  @action public onNewAdd = async(): Promise<void> => {
    if (this.newAdd.length > 10 || !this.newAdd) {
      message.warning('策略名称为1-10个字');
      return;
    }
    const res = await request<BaseData<any>>({
      url: api.newStrategy,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify({ name: this.newAdd }),
    });
    message.success(res.data);
    this.getAllStrategy();
    this.newAdd = '';
  };
}

export type IStrategyManagementStore = StrategyManagementStore;
