import { message } from 'antd';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import { NormalProgramme, request } from 'egenie-utils';
import { action, observable, toJS } from 'mobx';
import { api } from '../../utils';
import NewStategyStore from '../productAnalysis/newStrategy/newStrategyStore';
import StrategyManagementStore from './strategyManagement/strategyManagementStore';

export default class StrategyTaskStore {
  constructor() {
    this.initPage();
  }

  @observable public page = 1;

  @observable public taskCount = {
    finishNum: 0,
    totalNum: 0,
  };

  @observable public productList = [];

  @observable public productRef = null;

  @observable public loadStatus = 'loadMore'; // 滑动加载 loading正在加载 noMore 已经全部加载了 loadMore 加载完成  noContent暂无内容

  @observable public newStategyStore = new NewStategyStore({ parent: this });

  @observable public strategyList = [];

  @observable public activeTab = '0';

  @observable public strategyManagementStore = new StrategyManagementStore({ parent: this });

  @action public getDict = async(): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.getDict,
      method: 'post',
      data: {
        commonDictList: [
          'vendorShopList',
          'shopList',
          'strategyCreatorList',
          'strategyList',
        ],
      },
    });

    this.normalProgramme.filterItems.addDict({
      shopId: (res.data?.shopList || []).map((item) => ({
        value: item.key,
        label: item.value,
      })),
      vendorShopId: (res.data?.vendorShopList || []).map((item) => ({
        value: item.key,
        label: item.value,
      })),
      creator: (res.data?.strategyCreatorList || []).map((item) => ({
        value: item.key,
        label: item.value,
      })),
    });
  };

  @action public getCategory = async(parentCategoryId: string): Promise<ValueAndLabelData> => {
    const res = await request<BaseData<Array<{ categoryId: string; categoryIdName: string; leaf: boolean; }>>>({
      url: api.getCategoryList,
      method: 'post',
      data: {
        parentCategoryId,
        categoryType: 25,
      },
    });

    return (res.data || []).map((el) => ({
      label: el.categoryIdName,
      value: `${el.categoryId}`,
      isLeaf: el.leaf,
    }));
  };

  @action public initPage = async(): Promise<void> => {
    this.getTaskCount();
    this.getDict();
    this.onTabChange(this.activeTab);

    const goodsCategoryIdData = await this.getCategory('0');
    this.normalProgramme.filterItems.addDict({ goodsCategoryId: goodsCategoryIdData });
  };

  @action public onTabChange = (activeTab: string): void => {
    console.log('activeTab', activeTab);
    this.activeTab = activeTab;
    if (activeTab === '1') {
      this.onStrategyQuery(0, 1);
    } else {
      this.page = 1;
      this.onProductQuery();
    }
  };

  public normalProgramme: NormalProgramme = new NormalProgramme({
    count: 6,
    filterItems: [
      {
        field: 'shopId',
        label: '发布店铺',
        type: 'select',
      },
      {
        field: 'vendorShopId',
        label: '档口',
        type: 'select',
      },
      {
        field: 'goodsCategoryId',
        label: '类目',
        type: 'cascader',
        isParamList: true,
        showSearch: false,
        loadData: async(selectedOptions: ValueAndLabelData) => {
          const targetOption = selectedOptions[selectedOptions.length - 1];
          if (targetOption.children) {
            return;
          }

          function dfs(data: ValueAndLabelData, id: string): ValueAndLabelData[0] | null {
            if (!Array.isArray(data)) {
              return null;
            }

            for (let i = 0; i < data.length; i++) {
              if (data[i].value === id) {
                return data[i];
              }

              const subResult = dfs(data[i].children, id);
              if (subResult) {
                return subResult;
              }
            }

            return null;
          }

          const oldData = this.normalProgramme.filterItems.getFilterItem('goodsCategoryId').data;
          const targetItem = dfs(oldData, targetOption.value);
          if (!targetItem) {
            return;
          }

          targetOption.loading = true;
          try {
            targetItem.children = await this.getCategory(targetOption.value);
          } finally {
            targetItem.loading = false;
          }
        },
      },
      {
        field: 'creator',
        label: '策略创建人',
        type: 'select',
      },
      {
        field: 'brandName',
        label: '品牌',
        type: 'input',
      },
      {
        field: 'goodsName',
        label: '商品名称',
        type: 'input',
      },
      {
        field: 'platformId',
        label: '平台商品ID',
        type: 'input',
      },
    ],
    handleSearch: () => {
      this.page = 1;
      return this.onProductQuery();
    },
  });

  @action public getTaskCount = async(): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.getTaskCount,
      method: 'get',
    });

    this.taskCount = res.data;
  };

  @action public setProductRef = (ref): void => {
    this.productRef = ref;
  };

  @action public loadMore = (): void => {
    this.page += 1;
    this.onProductQuery();
  };

  // 查询策略面板
  @action public onProductQuery = () => {
    console.log('onProductQuery');
    this.loadStatus = 'loading';
    const params = this.normalProgramme.filterItems.params;
    return request<BaseData<any>>({
      url: api.queryGoodsTask,
      method: 'post',
      data: {
        page: this.page,
        pageSize: 20,
        sidx: '',
        sord: '',
        ...params,
        goodsCategoryId: params.goodsCategoryId ? params.goodsCategoryId[1] : params.goodsCategoryId,
      },
    })
      .then((res) => {
        const { data } = res;
        this.productList = data.page === 1 ? data.list : toJS(this.productList)
          .concat(data.list);
        if (data.page === 1 && !data.list.length) {
          this.loadStatus = 'noContent';
          return;
        }
        if (data.page >= data.totalPageCount) {
          this.loadStatus = 'noMore';
        } else {
          this.loadStatus = 'loadMore';
        }
      })
      .catch(() => {
        this.loadStatus = 'loadMore';
      });
  };

  @action public openStrategyManagement = (): void => {
    this.strategyManagementStore.onShow();
  };

  // 编辑策略
  @action public editStrategy = (goodsName: string, picUrl: string, gmsGoodsId: number, type: string): void => {
    const list = [
      {
        goodsName,
        smallPicUrl: picUrl,
        gmsGoodsId,
      },
    ];
    const cb = type === 'product' ? this.refreshProductStrategy : this.refreshStrategy;
    this.newStategyStore.onShow(list, 'edit', cb);
  };

  @action public refreshStrategy = (): void => {
    this.onStrategyQuery(0, 1);
    this.getTaskCount();
  };

  // 编辑商品策略  查询当前商品策略
  @action public refreshProductStrategy = async(gmsGoodsId: number): Promise<void> => {
    console.log('getCurrentStrategy');
    this.getTaskCount();
    const res = await request<BaseData<any>>({
      url: api.queryCurrentProductTask,
      method: 'post',
      data: { gmsGoodsId },
    });
    const list = [];
    for (let i = 0; i < res.data.length; i++) {
      const el = res.data[i];
      if (el.status === 2) {
        list.push(el);
      }
    }
    const item = this.productList.find((el) => el.gmsGoodsId === gmsGoodsId);
    item.goodsStrategyVoList = list;
  };

  // 点击checkbox 未完成->已完成
  @action public editStrategyStatus = async(gmsGoodsId: number, gmsGoodsStrategyId: number, type: string, gmsStrategyId?: number): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.editStrategyStatus,
      method: 'post',
      data: {
        gmsGoodsStrategyId,
        status: 1,
      },
    });
    res.data && message.success(res.data);
    res.info && message.error(res.info);
    this.getTaskCount();
    if (type === 'product') {
      const item = this.productList.find((el) => el.gmsGoodsId === gmsGoodsId);
      const _index = item.goodsStrategyVoList.findIndex((el) => el.gmsGoodsStrategyId === gmsGoodsStrategyId);
      item.goodsStrategyVoList.splice(_index, 1);
      console.log('item.goodsStrategyVoList', toJS(item.goodsStrategyVoList));
      if (!item.goodsStrategyVoList.length) {
        const _i = this.productList.findIndex((el) => el.gmsGoodsId === gmsGoodsId);
        this.productList.splice(_i, 1);
      }
    }
    if (type === 'strategy') {
      if (res.info) {
        const _i = this.strategyList.findIndex((el) => el.gmsStrategyId === gmsStrategyId);
        this.strategyList.splice(_i, 1);
        return;
      }
      const item = this.strategyList.find((el) => el.gmsStrategyId === gmsStrategyId);
      item.num -= 1;
      const { list } = item.voList;
      const _index = list.findIndex((el) => el.gmsGoodsId === gmsGoodsId);
      list.splice(_index, 1);
    }
  };

  @action public onStrategyQuery = async(gmsStrategyId: number, page: number): Promise<void> => {
    const data = await this.getStrategyList(gmsStrategyId, page);
    this.strategyList = data.map((el) => {
      const { voList } = el;
      el.loadStatus = voList.page >= voList.totalPageCount ? 'noMore' : 'loadMore';
      if (!el.voList.list.length) {
        el.loadStatus = 'noContent';
      }
      el.page = voList.page;
      el.voList.list = voList.list.map((item) => {
        item.hoverStatus = false;
        return item;
      });
      return el;
    });
  };

  @action public getStrategyList = async(gmsStrategyId: number, page: number) => {
    const res = await request<BaseData<any>>({
      url: api.queryStrategyTask,
      method: 'post',
      data: {
        gmsStrategyId,
        page,
        pageSize: 5,
      },
    });
    return res.data;
  };

  @action public onMouseEnterAndLeave = (gmsStrategyId: number, gmsGoodsId: number, status): void => {
    const _strategy = this.strategyList.find((el) => el.gmsStrategyId === gmsStrategyId);
    const _product = _strategy.voList.list.find((el) => el.gmsGoodsId === gmsGoodsId);
    _product.hoverStatus = status;
  };

  @action public strategyLoadMore = async(gmsStrategyId: number, page: number): Promise<void> => {
    const data = await this.getStrategyList(gmsStrategyId, page);
    const item = this.strategyList.find((el) => el.gmsStrategyId === gmsStrategyId);
    const { voList } = data[0];
    item.page = page;
    item.loadStatus = voList.page >= voList.totalPageCount ? 'noMore' : 'loadMore';
    item.voList.list = item.voList.list.concat(voList.list);
    console.log(toJS(this.strategyList));
  };
}

export type IParentStore = StrategyTaskStore;
