import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Switch } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styles from './index.less';
import type { IStrategyManagementStore } from './strategyManagementStore';

@observer
class StrategyManagement extends Component<{ store: IStrategyManagementStore; }> {
  render(): ReactNode {
    const {
      visible,
      onClose,
      list,
      editStrategy,
      onchange,
      onDelete,
      newAdd,
      onNewChange,
      onNewAdd,
    } = this.props.store;
    return (
      <Modal
        bodyStyle={{
          padding: '18px',
          borderRadius: '6px',
        }}
        closable={false}
        footer={null}
        maskClosable={false}
        open={visible}
        width={512}
        wrapClassName={styles.strategyManagement}
      >
        <div className={styles.strategyListWrap}>
          <div className={`${styles.flex} ${styles.flexBetween} ${styles.flexAlign} ${styles.mb20}`}>
            <span className={`${styles.cl3} ${styles.font18} ${styles.font500} ${styles.lh1}`}>
              策略管理
            </span>
            <CloseOutlined
              className={`${styles.font18} ${styles.clGrey99}`}
              onClick={onClose}
            />
          </div>
          <div className={styles.listWrap}>
            {
              list.map((el, i) => {
                return (
                  <div
                    className={i ? styles.mt20 : ''}
                    key={el.gmsStrategyId}
                  >
                    {
                      !el.isEditable ? (
                        <div className={`${styles.flex} ${styles.flexAlign}`}>
                          <div className={`${styles.serialNumber} ${el.isEnable ? styles.bgblue : styles.bggrey}`}>
                            {i + 1}
                          </div>
                          <div className={`${styles.flex} ${styles.flexAlign} ${styles.flexBetween} ${styles.flex1}`}>
                            <div className={`${styles.font14} ${styles.font400} ${styles.clblack} ${styles.ml10}`}>
                              {el.name}
                            </div>
                            <div className={`${styles.flex} ${styles.flexAlign}`}>
                              <Switch
                                checked={el.isEnable}
                                checkedChildren="on"
                                onChange={(v) => editStrategy(el.gmsStrategyId, 'isEnable', v)}
                                unCheckedChildren="off"
                              />
                              <i
                                className={`${'icon-edit'} ${styles.font20} ${styles.ml12} ${styles.clgrey7a}`}
                                onClick={(e) => onchange(el.gmsStrategyId, 'isEditable', true)}
                              />
                              <i
                                className={`${'icon-delete'} ${styles.font20} ${styles.ml12} ${styles.clgrey7a}`}
                                onClick={() => onDelete(el.gmsStrategyId)}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>

                          <div className={`${styles.flex} ${styles.flexAlign}`}>
                            <div className={`${styles.serialNumber} ${el.isEnable ? styles.bgblue : styles.bggrey}`}>
                              {i + 1}
                            </div>
                            <div
                              className={`${styles.flex} ${styles.flexBetween} ${styles.flex1}`}
                            >
                              <Input
                                className={`${styles.font14} ${styles.font400} ${styles.clblack} ${styles.ml10} ${styles.modifyInput} ${styles.h26}`}
                                onChange={(e) => onchange(el.gmsStrategyId, 'name', e.target.value)}
                                value={el.name}
                              />
                              <Button
                                className={`${styles.h26} ${styles.lh1}`}
                                ghost
                                onClick={(e) => editStrategy(el.gmsStrategyId, 'name', el.name)}
                                type="primary"
                              >
                                保存
                              </Button>
                            </div>
                          </div>
                          <div className={`${styles.ml26} ${styles.mt7} ${styles.font12} ${styles.font400} ${styles.clGrey99}`}>
                            该策略相关的未完成和已完成任务会被统一修改名称
                          </div>
                        </div>
                      )
                    }

                  </div>
                );
              })
            }
          </div>
          {
            list.length < 10 && (
              <div className={styles.mt22}>
                <Input
                  className={styles.newAddInput}
                  onChange={(e) => onNewChange(e.target.value)}
                  onPressEnter={onNewAdd}
                  placeholder="请输入运营策略(最多10字)"
                  value={newAdd}
                />
                <Button
                  className={styles.newAddBtn}
                  onClick={onNewAdd}
                  type="primary"
                >
                  新增
                </Button>
              </div>
            )
          }
        </div>
      </Modal>
    );
  }
}

export default StrategyManagement;
