import { Button, Progress, Tabs } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import NewStrategy from '../productAnalysis/newStrategy/newStrategy';
import styles from './index.less';
import Product from './product';
import StrategyTaskStore from './store';
import Strategy from './strategy';
import StrategyManagement from './strategyManagement/strategyManagement';

const store = new StrategyTaskStore();

@observer
class StrategyTask extends Component {
  render(): ReactNode {
    const {
      strategyManagementStore,
      openStrategyManagement,
      taskCount,
      newStategyStore,
      onTabChange,
    } = store;
    return (
      <div className={styles.strategyTask}>
        <div className={`${styles.titleContent} ${styles.pd16} ${styles.bgf} ${styles.flex} ${styles.flexAlign} ${styles.flexBetween}`}>
          <div className={styles.flex}>
            <span>
              策略任务
            </span>
            <Progress
              className={`${styles.progress} ${styles.ml10}`}
              percent={taskCount.finishNum / taskCount.totalNum * 100}
              showInfo={false}
            />
            <span className={`${styles.clgrey} ${styles.ml16}`}>
              已完成
            </span>
            <span className={`${styles.clgrey} ${styles.ml10}`}>
              <span className={styles.clblack}>
                {taskCount.finishNum}
              </span>
              /
              {taskCount.totalNum}
            </span>
          </div>
          <div>
            <span className={styles.clgrey88}>
              {moment(new Date())
                .format('YYYY-MM-DD HH:mm:ss')}
            </span>
            <Button
              className={`${styles.ml10} ${styles.h34}`}
              onClick={openStrategyManagement}
              type="primary"
            >
              策略管理
            </Button>
          </div>
        </div>
        <Tabs
          items={[
            {
              key: '0',
              label: '商品看板',
              children: <Product store={store}/>,
            },
            {
              key: '1',
              label: '策略看板',
              children: <Strategy store={store}/>,
            },
          ]}
          onChange={(activeTab) => onTabChange(activeTab)}
        />
        <StrategyManagement store={strategyManagementStore}/>
        <NewStrategy store={newStategyStore}/>
      </div>
    );
  }
}

export default StrategyTask;
