import { Button, Row, Col, Checkbox, Spin, Card } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styles from './index.less';
import type { IParentStore } from './store';
import { NormalProgrammeComponent } from 'egenie-utils';

@observer
class Product extends Component<{ store: IParentStore; }> {
  render(): ReactNode {
    const {
      productList,
      loadStatus,
      loadMore,
      editStrategy,
      editStrategyStatus,
      normalProgramme,
    } = this.props.store;
    return (
      <>
        <Card
          size="small"
          style={{ marginTop: 8 }}
        >
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>
        <div className={styles.scrollArea}>
          <Row gutter={10}>
            {
              productList.map((el) => {
                const {
                  gmsGoodsId,
                  goodsName,
                  picUrl,
                  shopName,
                  goodsCategoryName,
                  platformId,
                  goodsStrategyVoList,
                  newFlag,
                  lastUpdateTime,
                } = el;
                return (
                  <Col
                    key={gmsGoodsId}
                    span={6}
                  >
                    <div className={`${styles.productWrap} ${styles.mt10} ${styles.br4}`}>
                      <div className={`${styles.flex} ${styles.br4} ${styles.bgf} ${styles.pd10}`}>
                        <div className={`${styles.img} ${styles.br4} ${styles.poRe}`}>
                          <img
                            className={`${styles.img} ${styles.br4}`}
                            src={picUrl}
                          />
                          {newFlag && (
                            <div className={`${styles.newFlag} ${styles.newFlagProduct} ${styles.font12}`}>
                              新
                            </div>
                          )}
                        </div>
                        <div className={`${styles.ml10} ${styles.productContent} ${styles.lh1} ${styles.flex} ${styles.flexColumn} ${styles.flexBetween}`}>
                          <div>
                            <div
                              className={`${styles.font400} ${styles.font12} ${styles.productName}`}
                              style={{ lineHeight: '14px' }}
                            >
                              {goodsName}
                            </div>
                            <div
                              className={`${styles.mt10} ${styles.flex} ${styles.clgrey} ${styles.font12}`}
                              style={{ lineHeight: '14px' }}
                            >
                              <span style={{ whiteSpace: 'nowrap' }}>
                                {goodsCategoryName}
                              </span>
                              <div className={`${styles.ml24} ${styles.oneRow}`}>
                                店铺：
                                {shopName}
                              </div>
                            </div>
                            <div className={`${styles.mt10} ${styles.flex} ${styles.clgrey} ${styles.font12}`}>
                              平台商品ID：
                              {platformId}
                            </div>
                          </div>
                          <div className={`${styles.flex} ${styles.clgrey} ${styles.font12}`}>
                            <Button
                              className={styles.btns}
                              onClick={() => {
                                top.egenie.openTab(`/vendor-background/gms/publishProduct?goodsId=${gmsGoodsId}&pageType=1`, 'edit', '编辑商品');
                              }}
                            >
                              编辑商品
                            </Button>
                            <Button
                              className={`${styles.ml20} ${styles.btns}`}
                              onClick={() => editStrategy(goodsName, picUrl, gmsGoodsId, 'product')}
                            >
                              编辑策略
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className={styles.pd10}>
                        <div className={`${styles.font12} ${styles.clgrey88}`}>
                          最近更新：
                          {lastUpdateTime}
                        </div>
                        <div
                          className={styles.strategyList}
                        >
                          {
                            goodsStrategyVoList.map((item) => {
                              return (
                                <div
                                  className={styles.mt14}
                                  key={item.gmsGoodsStrategyId}
                                >
                                  <Checkbox onClick={() => editStrategyStatus(gmsGoodsId, item.gmsGoodsStrategyId, 'product')}>
                                    <span>
                                      {item.name}
                                    </span>
                                  </Checkbox>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                    <div/>
                  </Col>
                );
              })
            }
          </Row>
          <div className={`${styles.nomore} ${styles.flex} ${styles.flexCenter}`}>
            {loadStatus === 'loadMore' && (
              <span
                onClick={loadMore}
                style={{ cursor: 'pointer' }}
              >
                查看更多
                <i className="icon-arrow_unfold"/>
              </span>
            )}
            {loadStatus === 'noMore' && '没有更多了~'}
            {loadStatus === 'loading' && <Spin/>}
            {!productList.length && '暂无内容'}
          </div>
        </div>
      </>
    );
  }
}

export default Product;
