import { Button, Row, Col, Checkbox, Spin } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styles from './index.less';
import type { IParentStore } from './store';

@observer
class Strategy extends Component<{ store: IParentStore; }> {
  render(): ReactNode {
    const { strategyList, onMouseEnterAndLeave, strategyLoadMore, editStrategy, editStrategyStatus } = this.props.store;
    return (
      <Row
        className={styles.strategyScroll}
        gutter={10}
      >
        {
          strategyList.map((el) => {
            const { gmsStrategyId, name, num, loadStatus, page, voList: { list }} = el;
            return (
              <Col
                key={gmsStrategyId}
                span={6}
              >
                <div className={`${styles.strategyWrap} ${styles.mt10} ${styles.br4} ${styles.bgf}`}>
                  <div className={`${styles.pd16} ${styles.strategyTitle} ${styles.font14} ${styles.clblack} ${styles.font500e}`}>
                    {name}
                    (
                    {num}
                    )
                  </div>
                  <div
                    className={styles.strategyContent}
                  >
                    {
                      list.map((item) => {
                        const { gmsGoodsId, gmsGoodsStrategyId, picUrl, goodsName, goodsCategoryName, shopName, hoverStatus, newFlag } = item;
                        return (
                          <div
                            className={`${styles.w100} ${styles.mt18}`}
                            key={gmsGoodsId}
                          >
                            <div className={`${styles.flex} ${styles.flexAlign}`}>
                              <Checkbox onClick={() => editStrategyStatus(gmsGoodsId, gmsGoodsStrategyId, 'strategy', gmsStrategyId)}/>
                              <div className={`${styles.ml16} ${styles.flex} ${styles.strategyProduct}`}>
                                <div className={`${styles.img60} ${styles.br4} ${styles.poRe}`}>
                                  <img
                                    className={`${styles.img60} ${styles.br4}`}
                                    src={picUrl}
                                  />
                                  {newFlag && (
                                    <div className={`${styles.newFlag} ${styles.newFlagProduct} ${styles.font12}`}>
                                      新
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={`${styles.ml8} ${styles.strategyProductContent}`}
                                  onMouseEnter={() => onMouseEnterAndLeave(gmsStrategyId, gmsGoodsId, true)}
                                  onMouseLeave={() => onMouseEnterAndLeave(gmsStrategyId, gmsGoodsId, false)}
                                >
                                  <div
                                    className={`${styles.font400} ${styles.font12} ${styles.productName}`}
                                    style={{ lineHeight: '14px' }}
                                  >
                                    {goodsName}
                                  </div>
                                  <div className={`${styles.mt10} ${styles.flex} ${styles.clgrey} ${styles.font12}`}>
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                      {goodsCategoryName}
                                    </span>
                                    <div className={`${styles.ml24} ${styles.oneRow}`}>
                                      店铺：
                                      {shopName}
                                    </div>
                                  </div>
                                  <div
                                    className={`${styles.hoverBtnWrap} ${styles.flex} ${styles.flexAlign}`}
                                    style={{ display: hoverStatus ? 'flex' : 'none' }}
                                  >
                                    <Button
                                      className={`${styles.ml8} ${styles.hoverBtns}`}
                                      onClick={() => editStrategy(goodsName, picUrl, gmsGoodsId, 'stategy')}
                                    >
                                      编辑策略
                                    </Button>
                                    <Button
                                      className={`${styles.ml8} ${styles.hoverBtns}`}
                                      onClick={() => {
                                        top.egenie.openTab(`/vendor-background/gms/publishProduct?goodsId=${gmsGoodsId}&pageType=1`, 'edit', '编辑商品');
                                      }}
                                    >
                                      编辑平台商品
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    }
                    <div className={styles.lookMore}>
                      { loadStatus === 'loadMore' && (
                        <span

                          onClick={() => strategyLoadMore(gmsStrategyId, page + 1)}
                          style={{ cursor: 'pointer' }}
                        >
                          查看更多
                          <i className="icon-arrow_unfold"/>
                        </span>
                      )}
                      {/* { loadStatus === 'noMore' && '没有更多了~'}*/}
                      { loadStatus === 'loading' && <Spin/>}
                      { loadStatus === 'noContent' && '暂无内容'}
                    </div>
                  </div>
                </div>
              </Col>
            );
          })
        }
        {
          !strategyList.length && (
            <div className={`${styles.nomore} ${styles.flex} ${styles.flexCenter} ${styles.w100}`}>
              暂无内容
            </div>
          )
        }
      </Row>
    );
  }
}

export default Strategy;
